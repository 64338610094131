import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_12/slide3';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "slide-4-12-3-mobile"}}) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_11/4-11-2-bg__mobile.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    labels: mdx(
      frontmatter: {
        language: {eq: "EN"}
        title: {eq: "slide-4-12-3-labels"}
      }
      ) {
      exports {
        labels {
          meters
          millions
          caption1
          caption2
          caption3
        }
      }
    },
    modal41231:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "hungary-modals"}, selector: {eq: "modal41231"}}) {
      body
    },
    modal41232:  mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "hungary-modals"}, selector: {eq: "modal41232"}}) {
      body
    },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;

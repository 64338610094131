import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_12/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    mdx(frontmatter: {language: {eq: "EN"}, title: {eq: "slide-4-12-1"}}) {
      body
    }
    file(relativePath: { eq: "chapter_4/sub_12/mobile/4-12-1-bg.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed
          }
        }
    }
  }
  `);
  return (
    <Template query={query} title="Part XII" />
  );
};


export default Slide;
